body {
  margin: 0;
  padding: 0;
  background: #f0f0f0;
}
.dialog {
  width: 260px;
  height: 240px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -120px;
  margin-left: -140px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}
.dialog-title {
  margin: 0;
  padding: 15px 10px;
  text-align: center;
  color: #fff;
  font-weight: normal;
  background: #28a745;
}
.dialog-content {
  text-align: center;
  padding: 20px;
}

#m-check-mark {
  margin: 20px 0;
  width: 150px;
  height: auto;
  display: inline;
}

#checkmark {
  stroke-dasharray: 640;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation: draw-around 2s forwards;
  -ms-animation: draw-around 2s forwards;
  animation: draw-around 2s forwards;
}
@-ms-keyframes draw-around {
  0% {
    stroke-dashoffset: 640;
  }
  10% {
    stroke-dashoffset: 640;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes draw-around {
  0% {
    stroke-dashoffset: 640;
  }
  10% {
    stroke-dashoffset: 640;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-around {
  0% {
    stroke-dashoffset: 640;
  }
  10% {
    stroke-dashoffset: 640;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
