body {
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}

.custom-button {
  width: 200px;
  margin-top: 20px;
  float: right;
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: block;
  padding: 1em;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position:absolute;
  width: 30px;
  height: 20px;
  left: 36px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
