@import url(https://fonts.googleapis.com/css?family=Roboto);
body > #root > div {
  height: 100vh;
}

img {
  width: 100%;
}

.root {
  position: relative;
  width: 100%;
}

.App {
  position: relative;
  width: 100%;
}

.navbar {
  position: relative;
  widows: 100%;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
}

.xeroButton {
  float: left;
  width: 50%;
}

.workbookButton {
  float: right;
}

.radio-status {
  float: left;
  padding: 0em;
}

.item-delete {
  float: right;
}

.itemsAnimate-enter {
  opacity: 0.01;
}

.itemsAnimate-enter-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.itemsAnimate-exit {
  opacity: 0.01;
}

.itemsAnimate-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.parent-button-container {
  width: 100%;
  padding-bottom: 4em;
}

.parent-radio-container {
  width: 100%;
  padding-bottom: 3em;
}

/* .headingText {
  text-align: center;
  padding-bottom: 2em;
} */

p {
  margin-bottom: 0em !important;
}

.card-body {
  margin-bottom: 1em;
  padding: 0.75em;
}

.card {
  margin: 0em;
}

.paymentCard {
  box-shadow: 0px 6px 1px #ddd, 0 6px 6px rgb(51, 50, 50);
}

.paymentButton {
  margin-top: 3em;
  margin-bottom: 4em;
}

.list-group-items {
  font-size: 0.75em !important;
}

.list-group {
  padding-top: 1em;
}

.paymentTable {
  margin-left: 0.5em;
  width: 98%;
}

.navbar-dark .navbar-nav .nav-link.active {
  border-bottom: 1px solid #fff;
}

.landing {
  position: relative;
  background: url(/static/media/showcase.59f6b55f.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -24px;
  margin-bottom: -50px;
}

.landing-inner {
  padding-top: 80px;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
}

.navbar-brand {
  font-size: 11pt;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.table {
  margin-bottom: 0rem !important;
}

.table-striped {
  padding: 1.25em;
  margin-bottom: 1em !important;
}

.invoice-details {
  padding: 0.5em;
  cursor: pointer;
  margin-bottom: 1em !important;
}

.form-control::-webkit-input-placeholder {
  color: #bbb !important;
}

.form-control::placeholder {
  color: #bbb !important;
}

table.table-errors tr td {
  padding: 0rem !important;
  padding-left: 0.75rem !important;
  font-size: 9pt !important;
  border-top: 0px solid #dee2e6;
}

table.table-errors th {
  font-size: 9pt !important;
  border-top: 0px solid #dee2e6;
}

.table-errors {
  margin-top: 0.5em;
}

.navbar {
  background-color: #4885ed !important;
}

html {
  position: relative !important;
  min-height: 100% !important;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 100px !important;
  font-family: "Roboto", sans-serif !important;
}
.footer {
  /* position: absolute !important; */
  bottom: 0 !important;
  width: 100% !important;
  /* Set the fixed height of the footer here
   height: 10px !important;*/
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #4885ed;
  color: #fff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
}

.dashboard {
  text-align: center !important;
}

.btn-lg btn-info {
  margin-top: 3em;
}

.lead {
  padding-bottom: 3em;
}

.thead-dark th {
  background-color: slategrey !important;
  border-color: slategrey !important;
}

input {
  margin: 0.4em;
}

.headingText {
  width: 100%;
  height: 100%;
  position: relative;
  top: 20px;
  left: 0px;
  text-align: center;
}

.buttonWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  top: 75px;
  left: 0px;
}
.xeroButton {
  width: 50%;
  float: left;
  position: relative;
}
.workbookButton {
  width: 50%;
  float: right;
  position: relative;
}

.lineItemsButton {
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}
.radioContainer {
  margin-bottom: 3em;
  /* width: 100%;
  height: 100%;
  position: relative;
  top: 120px;
  left: 0px; */
}
.itemsContainer {
  margin-top: 2em;
  height: 100%;
  position: relative;
  top: 180px;
  left: 0px;
  padding-bottom: 100px;
}
.errorsContainer {
  height: 100%;
  position: relative;
  top: 180px;
  left: 10%;
  padding-bottom: 100px;
  width: 80%;
}

.infoLink {
  margin: 1em;
  padding-bottom: 0.5em;
  font-size: 14pt;
  font-weight: bolder;
  color: #4885ed;
}

.infoLinkSelected {
  border-bottom: 1px solid;
  color: #4885ed;
}

.infoComponents {
  margin-top: 3em;
}

.errorAlert {
  margin-top: 10em;
  border: 1px solid #bdbdbd;
  border-radius: 15px;
}

.itemsAnimate-enter {
  opacity: 0.01;
}

.itemsAnimate-enter.itemsAnimate-enter-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.itemsAnimate-leave {
  opacity: 0.01;
}

.itemsAnimate-leave.itemsAnimate-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* Button responsive overide */
.btn-sm {
  min-width: 80px;
}

.centreDisplay {
  text-align: center; 
  margin-top: 2.5em;
}

.centreBlock > canvas {
  margin: 1rem auto;
  border: #9e9e9e solid 2px;
}

.pdfNavbar > * {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.pdfBtn {
  width: 100px;
  padding: .2rem;
}

.pdfPages {
  width: 120px;
}

.loadingCanvas {
  width: 800px;
  height: 1131px;
}

.loadingCanvas > * {
  margin-left: 40%;
  margin-top: 40%;
}

.subHeading {
  color: #23608c;
  padding-bottom: 0.5em;
}

.errorStyle {
  width: 100%;
  font-size: 14px;
  color: #dc3545;
}
body {
  margin: 0;
  padding: 0;
  background: #f0f0f0;
}
.dialog {
  width: 260px;
  height: 240px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -120px;
  margin-left: -140px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}
.dialog-title {
  margin: 0;
  padding: 15px 10px;
  text-align: center;
  color: #fff;
  font-weight: normal;
  background: #28a745;
}
.dialog-content {
  text-align: center;
  padding: 20px;
}

#m-check-mark {
  margin: 20px 0;
  width: 150px;
  height: auto;
  display: inline;
}

#checkmark {
  stroke-dasharray: 640;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: draw-around 2s forwards;
  animation: draw-around 2s forwards;
}
@-webkit-keyframes draw-around {
  0% {
    stroke-dashoffset: 640;
  }
  10% {
    stroke-dashoffset: 640;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-around {
  0% {
    stroke-dashoffset: 640;
  }
  10% {
    stroke-dashoffset: 640;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}

.custom-button {
  width: 200px;
  margin-top: 20px;
  float: right;
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: block;
  padding: 1em;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position:absolute;
  width: 30px;
  height: 20px;
  left: 36px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

